// Here you can add other styles


.c-header.c-header-fixed {
    top: 0px;
}

.click-file {
    cursor: pointer;
}

// login
.auth-btn {
    background: #1874C4;
}

.auth-forward {
    color: #1874C4;
}

.btn-detail {
    min-width: 60px;
    width: 100%;
    line-height: 43px;
}

.btn-point_purchase_history {
    min-width: 138px;
    width: 100%;
    line-height: 43px;
}

.table th, .table td {
    vertical-align: middle !important;
}

.body-login {
    background: #F5F5F5;
}

.login-box {
    box-shadow: 0px 0px 10px #0000001A;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.box-shadow {
    box-shadow: 0px 0px 10px #0000001A;
}

.login-box-body {
    border: 0px;
}

a:hover {
    text-decoration: none;
}

.date-row {
    position: relative;

    .icon-row {
        right: 16px;
        cursor: pointer;

        .img-icon {
            position: absolute;
            right: 5px;
            width: 20px;
            height: 20px;
            top: 5px;
        }

        &.input-group-btn {
            position: absolute;
            top: 0;
            right: 15px;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 45px;
        }
    }
}

.custom-btn {
    padding: 3px 13px;
    border-radius: 3px;
    cursor: pointer;
    font-family: "Hiragino Kaku Gothic ProN", sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;

    &:focus {
        border-color: #1874C4;
        box-shadow: 0 0 0 0.2rem rgba(97, 154, 240, 0.5);
        outline: none;
    }
}

.custom-btn-secondary {
    background: #FAFAFA !important;
    border-color: #ced2d8;
    color: #666666;

    &:hover {
        color: #666666;
    }
}

.custom-btn-danger {
    background: #E58B8B !important;
    border-color: #E58B8B !important;
    color: #FEFEFE;

    &:hover {
        color: #FEFEFE;
    }
}

.custom-btn-primary {
    background-color: #1874C4 !important;
    border-color: #1874C4 !important;
    color: #FEFEFE;

    &:hover {
        color: #FEFEFE;
    }
}


.error {
    font-weight: 600;
    font-size: 14px !important;
}

.text-input:focus {
    border-color: #66afe9 !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
}

.text-error {
    background: #fff3f3 !important;
    border: 0.1rem solid #e05959 !important;
}

// width
.w-80p {
    width: 80px !important;;
}

.w-90p {
    width: 90px !important;
}

.w-100p {
    width: 100px !important;;
}

.w-120p {
    width: 120px !important;;
}

.w-140p {
    width: 140px !important;
}

.w-160p {
    width: 160px !important;;
}

.w-170p {
    width: 170px !important;
}

.w-180p {
    width: 180px !important;
}

.w-200p {
    width: 200px !important;
}

.w-240p {
    width: 240px !important;
}

.w-290p {
    width: 290px !important;
}

.w-300p {
    width: 300px !important;
}

.w-400p {
    width: 400px !important;
}

.w-415p {
    width: 415px !important;
}

.w-440p {
    width: 440px !important;
}

.w-full {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

// height
.h-30p {
    height: 30px !important;
}

.h-35p {
    height: 35px !important;
}

.image-box {
    display: flex;
    align-items: center;

    .file-name {
        font-family: "Hiragino Kaku Gothic ProN", sans-serif;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        text-align: left;
        color: #333333;
        margin-right: 16px;
    }

    .custom-image-file {
        position: relative;
        width: 150px;
        margin-right: 16px;

        .custom-image-file-input {
            opacity: 0;
            width: 100%;
        }

        .custom-image-file-label {
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, #F5F5F5 0%, #7B7B7B 100%);
            font-family: "Hiragino Kaku Gothic ProN", sans-serif;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            text-align: center;
            width: 100%;
            border: 0;
            height: 100%;
            border-radius: 3px;

            &:focus {
                outline: none;
            }
        }
    }
}


.datetimepicker-wrapper {
    .datetimepicker {
        border-radius: 0.25rem !important;
        color: #768192;
    }

    .datetimepicker-icon img {
        right: 10px !important;
    }
}

.link {
    font-family: "Hiragino Kaku Gothic ProN", sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #1874C4;
}

.limit-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.custom-btn-search {
    font-family: "Hiragino Kaku Gothic ProN", sans-serif;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    color: #FEFEFE;
    background-color: #0878DA !important;
}

.custom-page-title {
    font-family: "Hiragino Kaku Gothic ProN", sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #43425D;
    word-break: break-all;
}

.custom-btn-back {
    font-family: "Hiragino Kaku Gothic ProN", sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    border: 1px solid #CCCCCC !important;
    color: #666666 !important;
    background-color: #FAFAFA !important;
}

.image-url {
    width: 200px;
    height: 154.5px;
}

.break-word {
    word-break: break-word !important;
}

.mt-20p {
    margin-top: 20px !important;
}

.line-height-normal {
    line-height: normal;
}

.pointer-events-none {
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.show-dots {
    text-overflow: ellipsis;
    word-wrap: normal;
}

::-webkit-input-placeholder {
    color: #CCCCCC !important;
}

::-moz-placeholder {
    color: #CCCCCC !important;
}

::-ms-placeholder {
    color: #CCCCCC !important;
}

::placeholder {
    color: #CCCCCC !important;
}

.per-page {
    line-height: normal;
}

.filter-brightness-150 {
    filter: brightness(150%);
}

.c-sidebar-nav-item {
    .c-sidebar-nav-link {
        line-height: normal;
    }

    .custom-icon {
        .image:hover {
            filter: brightness(150%);
        }
    }
}

.btn-new {
    display: inline-block;
    width: 52px !important;
    height: 26px !important;
    font-size: 12px !important;
    min-width: 53px !important;
}

input[type="text"]:not([disabled]):not([readonly]),
select:not([disabled]):not([readonly]),
textarea:not([disabled]):not([readonly]) {
    background-color: #FDFDFD !important;
    border: 1px solid #CCCCCC;
}
